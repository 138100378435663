.waffle-and-popcorn {
	height: 0;
	overflow: visible;
	position: relative;
	top: -30px;

	img {
		height: 105px;
		position: absolute;
		bottom: 0;
		right: 50px;
	}
}

@media (max-width: 768px) {
	.waffle-and-popcorn {
		height: calc(50px - 8vw);
		top: -17px;
		img {
			height: calc(45px + 10vw);
			right: 6vw;
		}
	}
}
