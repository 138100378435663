.stats {
	// flex-wrap: wrap;
	text-align: left;
	// justify-content: space-between;
	padding: 20px 20px 20px 20px;

	&__inner {
		margin: auto;

		> div {
			// width: 33%;
			// min-width: 200px;
			// margin-bottom: 10px;
			display: inline-block;
			margin-right: 1rem;
		}
		> div:last-child {
			margin-right: 0;
		}
	}

	h3 {
		margin-bottom: 10px;
		text-align: left;
		width: 100%;
	}

	h4 {
		width: 100%;
		margin-bottom: 2px;
		opacity: 0.6;

		text-transform: uppercase;
		letter-spacing: 2px;
		font-weight: 400;
		font-size: 11px;
	}

	.number {
		font-size: 1.3em;
		font-weight: 700;
		font-family: Nunito, sans-serif;
	}
}

@media (max-width: 650px) {
	.stats {
		text-align: center;
		padding-bottom: 10px;
		h3 {
			margin-bottom: 20px;
			text-align: center;
		}
		&__inner {
			> div {
				width: 100%;
				margin-right: 0;
				margin-bottom: 10px;
			}
		}
	}
}
