.mr-waffle {
	height: 0;
	overflow: visible;
	position: relative;
	top: -30px;

	img {
		height: 88px;
		position: absolute;
		bottom: 0;
		right: 50px;
	}
}

@media (max-width: 768px) {
	.mr-waffle {
		height: calc(60px - 8vw);
		top: -17px;
		img {
			height: calc(50px + 5vw);
			right: 6vw;
		}
	}
}
