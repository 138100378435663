.giffle {
	margin: 20px;

	img {
		width: 100%;
	}
	// margin-top: 20px;
	// margin-left: 20px;
	// margin-right: 20px;
	// margin-bottom: 0px;
}
