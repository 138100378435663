* {
	user-select: none;
	-webkit-user-select: none;
	box-sizing: border-box;
}

html,
body {
	-webkit-tap-highlight-color: transparent;
	padding: 0;
	margin: 0;
	height: 100%;
	overscroll-behavior: contain;
	overflow: hidden;
}

html {
	font-size: var(--font-size);
	font-family: Lato, sans-serif;
	background: var(--background-colour);
}

body {
	display: flex;
	flex-direction: column;

	left: 0;
	margin: auto;
	max-width: 100%;
	padding: 0;
	position: relative;
	right: 0;
	width: 100%;

	background-color: var(--overlay);
	transition: background-color var(--easing-duration) var(--easing);
}

h1,
h2,
h3 {
	font-family: var(--title-font-family);
	font-weight: var(--title-font-weight);
}

h1,
h2,
h3,
h4 {
	margin: 0;
	text-align: center;
	text-transform: uppercase;
}

h1 {
	font-size: 1.5rem;
	letter-spacing: 0.1rem;
	line-height: 1.5rem;
}

h2 {
	font-size: 1rem;
	letter-spacing: 1px;
}

h3 {
	font-size: 1rem;
	letter-spacing: 1px;
}

h4 {
	font-size: 0.8rem;
	letter-spacing: 1px;
	color: var(--color-tone-3);
}

small {
	font-size: 1rem;
	opacity: 0.5;
	text-align: center;
}

hr {
	opacity: 0.3;
	border: 1px solid var(--color-tone-4);
	border-width: 1px 0 0 0;
	width: 100%;
}

p {
	color: var(--color-tone-3);
	font-size: var(--font-size);
	line-height: calc(var(--font-size) * 1.5);
}
