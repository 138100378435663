:root {
	// Ease-out exponential
	--easing: cubic-bezier(0.19, 1, 0.22, 1); // https://cubic-bezier.com/#.19,1,.22,1
	// Ease-in exponential
	--easing-reverse: cubic-bezier(0.5, 0.28, 0.91, 0.36); // https://cubic-bezier.com/#.9,0,.91,.02

	--easing-duration: 220ms;
	--easing-duration2: calc(var(--easing-duration) * 5);
	--easing-duration3: calc(var(--easing-duration) * 10);

	--color-tone-1: #1a1a1b;
	--color-tone-2: #454747;
	--color-tone-3: #a9abad;
	--color-tone-4: #dee0e3;
	--color-tone-5: #edeff1;
	--color-tone-6: #f6f8fa;
	--color-tone-7: #ffffff;

	--red: #e45c40;
	--green: #3eb489; // "Mint" https://coolors.co/3eb489
	--yellow: #e1ba4e;
	--blue: #3794b5;
	--purple: #b676bb;

	--game-width: 728px;

	--title-font-family: 'Nunito', sans-serif;
	--title-font-weight: 600;

	--font-size: 16px;
	--icon-button-size: 48px;
	--icon-size: 24px;
	--close-icon-size: 18px;

	--modal-header-height: 64px;
	--modal-text-colour: var(--background-text-colour);

	--shadow-1: 0px 5px 2px 0px rgba(0, 0, 0, 0.1), 3px 9px 9px 0px rgba(0, 0, 0, 0.1);
}

:root {
	--background-colour: white;
	--background-text-colour: white;
	--letter-background-colour: var(--color-tone-6);
	--overlay: rgba(0, 0, 0, 0.1);
	--board-background-colour: var(--color-tone-7);
}
