@import './variables.scss';
@import './fonts/index.scss';
@import './core.scss';

@import './App.scss';

html,
body {
	text-align: center;
	background-color: var(--color-tone-5);
	overflow: auto;
}

a {
	font-family: Nunito, sans-serif;
	font-weight: 900;
	font-size: 1.1em;
	color: var(--green);
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 1px;
	padding: 0;
	border: none;
}

.waffle,
.one-word-search,
.giffle {
	max-width: 70%;
	width: 300px;
}

p {
	width: 500px;
	margin: 0 auto;
	max-width: 90%;
}

h3,
h4 {
	color: var(--color-tone-1);
}
.number {
	color: var(--color-tone-1);
}

@import './components/header.scss';
@import './components/menu.scss';
@import './components/one-word-search.scss';
@import './components/waffle.scss';
@import './components/lifehacker-logo.scss';
@import './components/cnet-logo.scss';
@import './components/independent-logo.scss';
@import './components/the-verge-logo.scss';
@import './components/section.scss';
@import './components/row.scss';
@import './components/stats.scss';
@import './components/giffle.scss';
@import './components/menu-bar.scss';
@import './components/waffle-studio-logo.scss';
@import './components/mr-waffle.scss';

.logo {
	// width: 40vw;
	flex-shrink: 0;
}

.logo {
	width: 40vw;
	max-width: 140px;
	margin: 10px;
	svg {
		width: 100%;
		height: 100px;
		max-width: 100%;
		object-fit: contain;
	}
}

@media (max-width: 768px) {
	.logo {
		width: 50vw;
		margin: 0;
		max-width: 50%;
		svg {
			width: 180px;
			height: 80px;
			max-width: 80%;
		}
	}
}

@import './components/professor-cluck.scss';
@import './components/waffle-and-popcorn.scss';